import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Cards from "react-credit-cards-2";
import { Box } from "@mui/material";
import PricingCard from "./PricingCard";
import aioappLogo from "../../assets/aioappLogo.png";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { saveCardDetailHandler } from "../../Redux/Actions/UserActions";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { creditCardSchema } from "../../Yup";
import Confirmation from "./Confirmation";
import validator from "validator";

const initialValues = {
  name: "",
  cardNumber: "",
  expiry: "",
  cvc: "",
};
const CardDetails = ({ setProceedToCardDetails }) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState("");
  const [focused, setFocused] = useState("");
  const [proceedToCheckout, setProceedToCheckout] = useState(false);
  const [checkoutCard, setCheckoutCard] = useState(false);
  const user = useSelector((state) => state.allusers.loggedInUser);
  const plantoSubscribe = useSelector(
    (state) => state.userReducer.planToSubscribe
  );
  const dispatch = useDispatch();

  const handleExpiryChange = (e) => {
    let value = e.target.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, "");

    // Insert slashes (MM/YY format)
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2, 4);
    }
    console.log("Value", value);
    setExpiry(value);
    Formik.setFieldValue("expiry", e.target.value);
  };
  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  };

  const handleCardNumber = (e) => {
    let value = e.target.value
      .replace(/\s+/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();

    setCardNumber(value);
    Formik.setFieldValue("cardNumber", e.target.value);
  };

  const Formik = useFormik({
    initialValues: { initialValues },
    validationSchema: creditCardSchema,

    onSubmit: async (values) => {
      dispatch(saveCardDetailHandler(values, user, setProceedToCheckout));
    },
  });

  console.log(Formik.getFieldMeta("cvc"));

  return (
    <div className="  ">
      {proceedToCheckout ? (
        <Confirmation
          setProceedToCheckout={setProceedToCheckout}
          plantoSubscribe={plantoSubscribe}
        />
      ) : (
        <Grid container spacing={2} className=" mt-4 ">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="flex flex-col justify-center items-center"
          >
            <div className="">
              <div className="flex flex-row justify-between  text-center">
                <Typography className="loginHeading font-thin text-2xl ">
                  Card Details
                </Typography>
                <Button
                  onClick={() => setProceedToCardDetails(false)}
                  size="large"
                  className="bg-[black] mb-2"
                  //loading={isLoading}
                  loadingPosition="end"
                  variant="contained"
                >
                  <span>See Other PLANS</span>
                </Button>
              </div>
              <form
                className="flex flex-col items-center text-left "
                onSubmit={Formik.handleSubmit}
              >
                <Box
                  className="p-4 "
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 4,
                    minWidth: 600,
                    padding: "2px",
                  }}
                >
                  <Cards
                    className="paymentCard"
                    number={Formik.values.cardNumber}
                    name={Formik.values.name}
                    expiry={expiry}
                    cvc={Formik.values.cvc}
                    focused={focused}
                  />
                  <div>
                    <div className="mt-4 ">
                      <Typography
                        className="loginHeading font-light text-1xl"
                        htmlFor="label"
                      >
                        Name
                      </Typography>
                      <TextField
                        className="mt-2"
                        type="text"
                        id="name"
                        fullWidth
                        name="name"
                        placeholder="Name"
                        value={Formik.values.name}
                        onChange={Formik.handleChange}
                        onFocus={handleInputFocus}
                        onBlur={Formik.handleBlur}
                        error={Formik.errors.name && Formik.touched.name}
                        helperText={
                          Formik.errors.name && Formik.touched.name
                            ? Formik.errors.name
                            : null
                        }
                      />
                    </div>
                    <div className="mt-4">
                      <Typography
                        className="loginHeading font-light text-1xl"
                        htmlFor="cardNumber"
                      >
                        Card Number
                      </Typography>
                      <TextField
                        className="mt-2"
                        id="cardNumber"
                        name="cardNumber"
                        placeholder="0000 0000 0000 0000"
                        inputProps={{ maxLength: 19 }}
                        fullWidth
                        value={cardNumber}
                        onChange={handleCardNumber}
                        onFocus={handleInputFocus}
                        onBlur={Formik.handleBlur}
                        error={
                          Formik.errors.cardNumber && Formik.touched.cardNumber
                        }
                        helperText={
                          Formik.errors.cardNumber && Formik.touched.cardNumber
                            ? Formik.errors.cardNumber
                            : null
                        }
                      />
                    </div>

                    <div className="mt-4">
                      <Typography className="loginHeading font-light text-1xl">
                        Expiry
                      </Typography>
                      <TextField
                        className="mt-2"
                        type="text"
                        fullWidth
                        name="expiry"
                        placeholder="MM / YY"
                        inputProps={{ maxLength: 5 }}
                        value={expiry}
                        onChange={handleExpiryChange}
                        onFocus={handleInputFocus}
                        onBlur={Formik.handleBlur}
                        error={Formik.errors.expiry && Formik.touched.expiry}
                        helperText={
                          Formik.errors.expiry && Formik.touched.expiry
                            ? Formik.errors.expiry
                            : null
                        }
                      />
                    </div>
                    <div className="mt-4">
                      <Typography
                        className="loginHeading font-light text-1xl"
                        htmlFor="cvc"
                      >
                        CVC
                      </Typography>
                      <TextField
                        className="mt-2"
                        type="text"
                        fullWidth
                        id="cvc"
                        name="cvc"
                        placeholder="123"
                        value={Formik.values.cvc}
                        inputProps={{ maxLength: 3 }}
                        onChange={Formik.handleChange}
                        onFocus={handleInputFocus}
                        onBlur={Formik.handleBlur}
                        error={Formik.errors.cvc && Formik.touched.cvc}
                        helperText={
                          Formik.errors.cvc && Formik.touched.cvc
                            ? Formik.errors.cvc
                            : null
                        }
                      />
                    </div>
                    <div className="flex flex-row justify-center">
                      <Button
                        disabled={!Formik.dirty || !Formik.isValid}
                        size="large"
                        className={
                          !Formik.dirty || !Formik.isValid
                            ? "mt-4 bg-[#AAAAAA] text-white"
                            : " mt-4 bg-[#2e74ba] loginHeading hover:bg-stone-900 "
                        }
                        type="submit"
                        //loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                      >
                        <span>Submit</span>
                      </Button>
                    </div>
                  </div>
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CardDetails;
