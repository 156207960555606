import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import { reducers } from "../Reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { createStoreHook } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const persistConfig = {
//   key: "main-root",
//   storage,
// };
// const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = [thunk];

// const store = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

// const Persistor = persistStore(store);

// export { Persistor };
export default store;
