import React from "react";
import { useState } from "react";
export const UserContext = React.createContext();

export default function SignupContext(props) {
  const [role, setRole] = useState("");
  const [routeDashboard, setDashboard] = useState();

  return (
    <UserContext.Provider
      value={{ role, setRole, setDashboard, routeDashboard }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
