import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { savePlantoSubscribe } from "../../Redux/Actions/UserActions";
import { toast, ToastContainer } from "react-toastify";

const PricingCard = ({ plan, setProceedToCardDetails }) => {
  const dispatch = useDispatch();
  const getUser = sessionStorage.getItem("userInfo");
  let user = JSON.parse(getUser);

  let purchaseStatus = sessionStorage.getItem("planPurchased");

  const subscribeHandler = () => {
    if (user.paymentMethodId || purchaseStatus) {
      toast.info(
        "You've already subscribed to a plan. Only one plan can be active at a time."
      );
      return;
    } else {
      console.log(plan);
      dispatch(savePlantoSubscribe(plan));
      setProceedToCardDetails(true);
    }
  };
  console.log("inside pricing");
  return (
    <div>
      <ToastContainer />
      <Box
        sx={{
          width: "100%",
          maxWidth: 460,
          minHeight: 470,
          bgcolor: "background.paper",
          boxShadow: 5,
        }}
        className="relative"
      >
        <Box className="pt-2  border-2 border-slate bg-[#2e74ba] text-white rounded-b-xl">
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography className="pl-2" variant="body2">
                {plan.category}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            className="mt-1 font-bold text-center"
            gutterBottom
            variant="h5"
            component="div"
          >
            {plan.type}
          </Typography>
        </Box>
        <Box className="text-center mt-4">
          <Grid container>
            <Grid item xs>
              <Typography
                className="loginHeading font-bold text-[#2e74ba] inline-block"
                variant="h3"
              >
                ${plan.price}{" "}
              </Typography>

              <Typography
                className=" inline-block"
                sx={{ fontStyle: "italic" }}
                color="text.secondary"
                variant="body1"
              >
                / {plan.interval}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="middle" />

        <div className="m-3 overflow-hidden  text-left">
          <Typography gutterBottom variant="body1">
            Features
          </Typography>

          <div className="max-h-52 overflow-y-auto">
            {plan.features.length > 0 && plan.features[0].name !== "" ? (
              plan.features?.map((feature) => {
                return (
                  <Stack spacing={1} className="mt-2">
                    <Chip label={feature.name} />
                  </Stack>
                );
              })
            ) : (
              <div>
                <Stack spacing={1} className="mt-2">
                  <Chip label={"No Feature Added"} />
                </Stack>
              </div>
            )}
          </div>
        </div>

        <div className=" absolute inset-x-0  bottom-0 mb-2">
          <div className="">
            <Button
              size="large"
              onClick={subscribeHandler}
              variant="contained"
              className="bg-[#2e74ba] mb-2"
            >
              <span>Subscribe</span>
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default PricingCard;
