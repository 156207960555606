import { ActionTypes } from "../Constants/ActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import request from "./Interceptors";

export const getAllUsers = (setDeleteStatus) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        setDeleteStatus(true);
        dispatch({ type: ActionTypes.GET_ALL_USERS, payload: res.data.users });
      });
  };
};
export const setLoggedInUser = (user) => {
  sessionStorage.setItem("userInfo", JSON.stringify(user));
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SET_LOGGEDIN_USER, payload: user });
  };
};
export const getAllFranchise = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/franchises`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_ALL_FRANCHISES,
          payload: res.data.franchises,
        });
      });
  };
};
export const getAllGoals = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/goals`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_ALL_GOALS,
          payload: res.data.goals,
        });
      });
  };
};
export const getAllInterests = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/interests`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_ALL_INTERESTS,
          payload: res.data.interests,
        });
      });
  };
};
export const getAllGenders = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/genders`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_ALL_GENDERS,
          payload: res.data.genders,
        });
      });
  };
};
export const addFranchise = (values, dialCode, phoneNumber, navigate) => {
  console.log(values);
  console.log(dialCode + phoneNumber);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/addFranchise`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNo: "+" + dialCode + phoneNumber,
          companyName: values.companyName,
          roleInCompany: values.roleInCompany,
          role: values.role,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllFranchise());
        toast.success("Partner added successfully");
      })
      .catch((err) => {
        setTimeout(() => {
          toast.error(`Error! ${err.response.data.message}`);
        }, 1000);
        sessionStorage.setItem("currentTab", "admin");
        sessionStorage.removeItem("admin token");
        navigate("/");
      });
  };
};
export const addGoals = (values, subCategories, imageURL) => {
  console.log(imageURL);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await request
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/addGoal`,
        {
          name: values.name,
          icon: values.link ? values.link : imageURL,
          subGoals: subCategories,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllGoals());
        toast.success("Goal added sucessfully");
        console.log(res);
      })
      .catch((err) => {
        toast.error(err.response.data.message[0]);
      });
  };
};
export const addQuestion = (values, options) => {
  console.log(options);
  console.log(values);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/addQuestion`,
        {
          name: values.name,

          options: options,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllFeedback());
        console.log(res);
      });
  };
};
export const addInterest = (values) => {
  console.log(values);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/addInterest`,
        {
          name: values.name,
          icon: values.link,
          active: values.active,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllInterests());
        console.log(res);
      });
  };
};
export const addGender = (values) => {
  console.log(values);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/addGender`,
        {
          name: values.name,
          icon: values.link,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllGenders());
        console.log(res);
      });
  };
};
export const addRole = (values) => {
  console.log(values);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/addRole`,
        {
          name: values.name,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllRoles());
        console.log(res);
      });
  };
};
export const updateInterest = (editableInterest, updatedInterest) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/interest/${editableInterest._id}`,
        {
          name: updatedInterest.name
            ? updatedInterest.name
            : editableInterest.name,
          icon: updatedInterest.icon
            ? updatedInterest.icon
            : editableInterest.icon,
          active: updatedInterest.active
            ? updatedInterest.active
            : editableInterest.active,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllInterests());
        console.log(res);
      });
  };
};
export const updateGender = (editableGender, updatedGender) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/gender/${editableGender._id}`,
        {
          name: updatedGender.name ? updatedGender.name : editableGender.name,
          icon: updatedGender.icon ? updatedGender.icon : editableGender.icon,
          active: updatedGender.active
            ? updatedGender.active
            : editableGender.active,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllGenders());
        console.log(res);
      });
  };
};
export const updateGoal = (editableGoal, updatedGoal) => {
  console.log("Updated", updatedGoal);
  console.log("Previous", editableGoal);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/goal/${editableGoal._id}`,
        {
          name: updatedGoal.name ? updatedGoal.name : editableGoal.name,
          icon: updatedGoal.icon ? updatedGoal.icon : editableGoal.icon,
          active: updatedGoal.active ? updatedGoal.active : editableGoal.active,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllGoals());
        console.log(res);
      });
  };
};
export const updateQuestion = (editableQuestion, updatedQuestion) => {
  console.log("Updated", updatedQuestion);
  console.log("Previous", editableQuestion);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/question/${editableQuestion._id}`,
        {
          name: updatedQuestion.name
            ? updatedQuestion.name
            : editableQuestion.name,
          options: updatedQuestion.options
            ? updatedQuestion.options
            : editableQuestion.options,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllFeedback());
        console.log(res);
      });
  };
};
export const updateRole = (editableRole, updatedRole) => {
  console.log(updatedRole);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/role/${editableRole._id}`,
        {
          name: updatedRole.name ? updatedRole.name : editableRole.name,
          active: updatedRole.active ? updatedRole.active : editableRole.active,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getAllRoles());
        console.log(res);
      });
  };
};
export const editFranchise = (
  editableFranchise,
  updatedFranchise,
  dialCode,
  phoneNumber
) => {
  console.log(editableFranchise);
  console.log(updatedFranchise);
  console.log(dialCode, phoneNumber);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/edit/${editableFranchise._id}`,
        {
          firstName: updatedFranchise.firstName
            ? updatedFranchise.firstName
            : editableFranchise.firstName,
          lastName: updatedFranchise.lastName
            ? updatedFranchise.lastName
            : editableFranchise.lastName,

          email: updatedFranchise.email
            ? updatedFranchise.email
            : editableFranchise.email,
          phoneNo:
            dialCode && phoneNumber
              ? "+" + dialCode + phoneNumber
              : editableFranchise.phoneNo,
          companyName: updatedFranchise.companyName
            ? updatedFranchise.companyName
            : editableFranchise.companyName,
          roleInCompany: updatedFranchise.roleInCompany
            ? updatedFranchise.roleInCompany
            : editableFranchise.roleInCompany,
          role: updatedFranchise.role
            ? updatedFranchise.role
            : editableFranchise.role,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res, values) => {
        dispatch(getAllFranchise());
        console.log(res);
      });
  };
};
export const getAllCounts = (setIsLoading) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/allCounts`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        setIsLoading(false);
        dispatch({
          type: ActionTypes.GET_ALL_COUNTS,
          payload: res.data,
        });
      });
  };
};
export const getSingleInterest = (id) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/interest/${id}`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: ActionTypes.SET_SINGLE_INTEREST,
          payload: res.data.interest,
        });
      });
  };
};
export const getAllRoles = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_ALL_ROLES,
          payload: res.data.roles,
        });
      });
  };
};
export const getAllFeedback = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/questions`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: ActionTypes.GET_ALL_FEEDBACK,
          payload: res.data.questions,
        });
      });
  };
};
export const resetPassword = (values, loggedInUser) => {
  console.log(values, loggedInUser);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/change-password`,
        {
          email: loggedInUser.email,
          currentPassword: values.currentpassword,
          newPassword: values.newPassword,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        //dispatch(getAllRoles());
        console.log(res);
      });
  };
};
export const getSingleUser = (userId, setSingleUser) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/view/${userId}`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        setSingleUser(res.data.user);
        // dispatch({
        //   type: ActionTypes.SET_SINGLE_USER,
        //   payload: res.data.user,
        // });
      });
  };
};
export const getSingleGoal = (goalId, setSingleGoal) => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/goal/${goalId}`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        setSingleGoal(res.data.goal);
        // dispatch({
        //   type: ActionTypes.SET_SINGLE_GOAL,
        //   payload: res.data.goal,
        // });
      });
  };
};
export const addPlan = (values, features) => {
  console.log(values, features);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/subscription/createPlan`,
        {
          type: values.type,
          category: values.category,
          price: values.price,
          interval: values.interval,
          features: features,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        toast.success("Plan added sucessfully");
        dispatch(getAllPlans());
        console.log(res);
      });
  };
};
export const getAllPlans = () => {
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/allPlans`, {
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        console.log(res);

        dispatch({ type: ActionTypes.GET_ALL_PLANS, payload: res.data.plans });
      });
  };
};
export const forgetPasswordConfirmation = (values, dialCode, phoneNumber) => {
  console.log(values);
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/user/reset-password`, {
        countryCode: dialCode,
        phoneNo: phoneNumber,
        newPassword: values.newPassword,
      })
      .then((res) => {
        toast.success(res.data.message);
        //dispatch(getAllRoles());
        console.log(res);
      });
  };
};
export const getPlansForAdmin = (selectedType, setLoading) => {
  console.log("inside plans for admin", selectedType);
  const BearerToken = sessionStorage.getItem("admin token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/allPlans`, {
        params: {
          type: selectedType,
        },
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);

        dispatch({
          type: ActionTypes.GET_ALL_PLANS_FOR_ADMIN,
          payload: res.data.plans,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};
