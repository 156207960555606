export const ActionTypes = {
  SET_LOGGEDIN_USER:"SET_LOGGEDIN_USER",
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_PLANS: "GET_ALL_PLANS",
  GET_ALL_PLANS_FOR_USERS:"GET_ALL_PLANS_FOR_USERS",
  GET_ALL_FEEDBACK: "GET_ALL_FEEDBACK",
  GET_ALL_ROLES: "GET_ALL_ROLES",
  GET_ALL_GENDERS: "GET_ALL_GENDERS",
  GET_ALL_COUNTS: "GET_ALL_COUNTS",
  GET_ALL_FRANCHISES: "GET_ALL_FRANCHISES",
  GET_ALL_INTERESTS: "GET_ALL_INTERESTS",
  EDIT_FRANCHISE: "EDIT_FRANCHISE",
  GET_ALL_GOALS: "GET_ALL_GOALS",
  ADD_FRANCHISE: "ADD_FRANCHISE",
  ADD_USER_FROM_FRANCHISE: "ADD_USER_FROM_FRANCHISE",
  GET_ALL_FRANCHISEE_USERS:"GET_ALL_FRANCHISEE_USERS",
  SET_SINGLE_INTEREST: "SET_SINGLE_INTEREST",
  SET_SINGLE_USER: "SET_SINGLE_USER",
  SET_SINGLE_GOAL: "SET_SINGLE_GOAL",
  CLEAR_ALL_PLANS:"CLEAR_ALL_PLANS",
  GET_ALL_PLANS_FOR_ADMIN:"GET_ALL_PLANS_FOR_ADMIN",
  PLAN_TO_SUBSCRIBE:"PLAN_TO_SUBSCRIBE",
  USER_CARD_DETAILS:"USER_CARD_DETAILS",
};
