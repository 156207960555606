import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import SignupContext from "./Context/SignupContext";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import ForgotPassword from "./Pages/ForgotPassword.js/ForgotPassword";
import CheckoutForm from "./Pages/Stripe/CheckoutForm";
import AdminRouteContext from "./Context/AdminRouteContext";
import { ProtectedRoutePayments } from "./Routes/ProtectedRoute";
import { Navigate } from "react-router-dom";
import PaymentMain from "./Pages/Stripe/PaymentMain";

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <SignupContext>
          <AdminRouteContext>
            <Routes>
              {/* <Route
                exact
                path="/franchise/*"
                element={
                  <ProtectedRoute>
                    <FranchiseDashboardMain />
                  </ProtectedRoute>
                } */}
              
              {/* <Route
                exact
                path="*"
                element={
                  <ProtectedRoute>
                    <AdminDashboardMain />
                  </ProtectedRoute>
                }
              /> */}

              <Route
                exact
                path="/forgotPassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/buySubscription"
                element={
                  <ProtectedRoutePayments>
                    <CheckoutForm />
                  </ProtectedRoutePayments>
                }
              />

              <Route exact path="/aiomegaPayments" element={<PaymentMain />} />
              <Route path="/" element={ <Navigate to="/aiomegaPayments" /> } />
            </Routes>
          </AdminRouteContext>
        </SignupContext>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
