import React from 'react'
import LoginSignUpMain from "../Login&SignUp/LoginSignUpMain";
import { useLocation } from "react-router-dom";
const PaymentMain = () => {
    const location = useLocation()
    console.log(location);
  return (
    <div>
        <LoginSignUpMain pathname={location.pathname}/>
    </div>
  )
}

export default PaymentMain