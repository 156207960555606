import { combineReducers } from "redux";
import { AdminReducers } from "./AdminReducers";
import { FranchiseReducer } from "./FranchiseReducer";
import { UserReducer } from "./UserReducer";

export const reducers = combineReducers({
  allusers: AdminReducers,
  franchiseeUsers:FranchiseReducer,
  userReducer:UserReducer
});
