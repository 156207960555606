import Typography from "@mui/material/Typography";
import React from "react";
import loginImage from "../../assets/loginImage.jpeg";
import aioappLogo from "../../assets/aioappLogo.png";
import SignIn from "./Signin";
import "../../App.css";

const LoginSignUpMain = ({pathname}) => {
  return (
    <div>
      <div className="h-100  d-flex">
        <div className="col-lg-7 col-md-6 col-sm-6 relative">
          <img
            className="loginImage vh-100 "
            src={loginImage}
            alt="login page"
          />
          {/* <div className="absolute inset-80 ">
                <Typography className="loginHeading font-bold text-3xl text-[#ffffff]">Stronger</Typography>
                <Typography className="loginHeading font-thin text-1xl text-[#ffffff]">Higher</Typography>
                <Typography className="loginHeading font-bold text-3xl text-[#ffffff]">Faster</Typography>
                <Typography className="loginHeading font-bold text-3xl text-[#ffffff]">Safer</Typography>
             </div> */}
        </div>
        <div className="col-lg-5 col-md-6 col-sm-6">
          <div className=" h-40 mt-40">
            <div className="flex sm:flex-col md:flex-col lg:flex-col xl:flex-row justify-center items-center ">
              <Typography className="loginHeading font-thin " variant="h3">
                Welcome to
              </Typography>
              <img src={aioappLogo} alt="login page"></img>
            </div>

            <div className="flex justify-center items-center ">
              <div
                className="flex justify-between items-center  "
                style={{ width: "20rem" }}
              >
                <Typography>
                  <span className="loginHeading font-thin">Stronger</span>
                </Typography>
                <Typography>
                  <span className="loginHeading font-thin">Higher</span>
                </Typography>
                <Typography>
                  <span className="loginHeading font-thin">Faster</span>
                </Typography>
                <Typography>
                  <span className="loginHeading font-thin">Safer</span>
                </Typography>
              </div>
            </div>
          </div>

          <div className="md:p-4">
            <SignIn pathname={pathname}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignUpMain;
