import { ActionTypes } from "../Constants/ActionTypes";
const initialState = {
  plansForUsers: [],
  planToSubscribe: {},
 
};
export const UserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ALL_PLANS_FOR_USERS:
      return { ...state, plansForUsers: payload };
    case ActionTypes.CLEAR_ALL_PLANS:
      return { ...state, plansForUsers: [] };
    case ActionTypes.PLAN_TO_SUBSCRIBE:
      return { ...state, planToSubscribe: { ...payload } };

    default:
      return state;
  }
};
