import React from "react";
import { Navigate } from "react-router-dom";
export const ProtectedRoute = ({ children }) => {
  const token = sessionStorage.getItem("admin token");
  const userToken = sessionStorage.getItem("user token");
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export const ProtectedRoutePayments = ({ children }) => {
  const userToken = sessionStorage.getItem("user token");
  const token = sessionStorage.getItem("admin token");

  if (!userToken) {
    return <Navigate to="/aiomegaPayments" replace />;
  }
  return children;
};
