import { ActionTypes } from "../Constants/ActionTypes";
const initialState = {
  franchiseeUsers: [],
};
export const FranchiseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ALL_FRANCHISEE_USERS:
      return { ...state, franchiseeUsers: payload };

    default:
      return state;
  }
};
