import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { subscribePlan } from "../../Redux/Actions/UserActions";
import Thanks from "./Thanks";
const Confirmation = ({ plantoSubscribe }) => {
  const [proceedToThanks, setProceedToThanks] = useState(false);
  const customer = useSelector((state) => state.allusers.loggedInUser);
  const dispatch = useDispatch();
  const confirmSubscription = () => {
    setProceedToThanks(true);
    console.log("proceed to thanks");
    dispatch(
      subscribePlan(plantoSubscribe.id, customer.customerId, setProceedToThanks)
    );
  };
  console.log("Confirmation Screen", plantoSubscribe);
  return (
    <div>
      {proceedToThanks ? (
        <Thanks />
      ) : (
        <Grid container className="flex flex-row mt-20">
          <Grid className="" item xs={12} sm={12} md={12} lg={6}>
            <div className="flex flex-col items-center  ">
              <Typography className="flex text-left loginHeading text-2xl font-light">
                Your Plan Details
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 460,
                  minHeight: 470,
                  bgcolor: "background.paper",
                  boxShadow: 5,
                }}
                className="relative mt-3"
              >
                <Box className="pt-2  bg-[#2e74ba] text-white rounded-b-xl">
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography className="pl-2" variant="body2">
                        {plantoSubscribe?.category}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    className="mt-1 font-bold text-center"
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    {plantoSubscribe?.type}
                  </Typography>
                </Box>
                <Box className="text-center mt-4">
                  <Grid container>
                    <Grid item xs>
                      <Typography
                        className="loginHeading font-bold text-[#2e74ba] inline-block"
                        variant="h3"
                      >
                        ${plantoSubscribe?.price}{" "}
                      </Typography>

                      <Typography
                        className=" inline-block"
                        sx={{ fontStyle: "italic" }}
                        color="text.secondary"
                        variant="body1"
                      >
                        / {plantoSubscribe?.interval}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider variant="middle" />
                <div className="m-3 overflow-hidden  text-left">
                  <Typography gutterBottom variant="body1">
                    Features
                  </Typography>

                  <div className="max-h-52 overflow-y-auto">
                    {plantoSubscribe?.features?.length > 0 ? (
                      plantoSubscribe.features?.map((feature) => {
                        return (
                          <Stack spacing={1} className="mt-2">
                            <Chip label={feature.name} />
                          </Stack>
                        );
                      })
                    ) : (
                      <div>
                        <Stack spacing={1} className="mt-2">
                          <Chip label={"No Feature Added"} />
                        </Stack>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </div>
          </Grid>
          <Grid className="" item xs={12} sm={12} md={12} lg={6}>
            <div className="px-20 mt-4 relative">
              <Typography className="loginHeading text-4xl font-bold ">
                Welcome to AIOMEGA Family
              </Typography>
              {/* <Typography className="loginHeading text-1xl font-light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography> */}
              <div className=" flex justify-center absolute inset-x-0  mt-4 ">
                <div className="">
                  <Button
                    size="large"
                    onClick={confirmSubscription}
                    variant="contained"
                    className="bg-[black] "
                  >
                    <span>CONFIRM PAYMENT</span>
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Confirmation;
