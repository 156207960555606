import { ActionTypes } from "../Constants/ActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import request from "./Interceptors";

export const getPlansForUser = (selectedType, setLoading) => {
  const BearerToken = sessionStorage.getItem("user token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/allPlans`, {
        params: {
          type: selectedType ? selectedType : "Non-User of AIO Device",
        },
        headers: { Authorization: `bearer ${BearerToken}` },
      })
      .then((res) => {
        console.log("get plan for user", res);
        setLoading(false);

        dispatch({
          type: ActionTypes.GET_ALL_PLANS_FOR_USERS,
          payload: res.data.plans,
        });
      })
      .catch((err) => {
        console.log("Error", err);
        toast.error(err.response.data.message);
      });
  };
};
export const clearAllPlans = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_ALL_PLANS });
  };
};
export const saveCardDetailHandler = (values, user, setProceedToCheckout) => {
  console.log(values);
  setProceedToCheckout(true);
  const BearerToken = sessionStorage.getItem("user token");
  return async (dispatch) => {
    await request
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-payment-method`,
        {
          customerId: user.customerId,
          cardNumber: values.cardNumber,
          expMonth: values.expiry.slice(0, 2),
          expYear: values.expiry.slice(3, 5),
          cvc: values.cvc,
        },
        {
          headers: { Authorization: `bearer ${BearerToken}` },
        }
      )
      .then((res) => {
        dispatch(getPlansForUser());
        toast.success("Card Details saved successfully");
        setProceedToCheckout(true);
      })
      .catch((err) => {
        setProceedToCheckout(true);
        setTimeout(() => {
          toast.error(`Error! ${err.response.data.message}`);
        }, 1000);
      });
  };
};
export const subscribePlan = (planId, setProceedToThanks) => {
  console.log("user action", planId);
  const userToken = sessionStorage.getItem("user token");
  return async (dispatch) => {
    await request
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/subscription/subscribe-to-plan`,
        {
          planId: planId,
        },
        {
          headers: { Authorization: `bearer ${userToken}` },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(getPlansForUser());
        toast.success("You have succesfully subscribe the plan");
        setProceedToThanks(true);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          toast.error(`Error! ${err.response.data.message}`);
        }, 1000);
      });
  };
};
export const savePlantoSubscribe = (plan) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.PLAN_TO_SUBSCRIBE, payload: plan });
  };
};
