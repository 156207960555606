import * as Yup from "yup";
import valid from "card-validator";
// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const signupSchema = Yup.object({
  email: Yup.string()
    .email("Email Must be Valid")
    .required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
});
export const resetPasswordSchema = Yup.object({
  currentpassword: Yup.string().required("Please enter your password"),
  newPassword: Yup.string().required("Please enter your password"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
});
export const addUserSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your name")
    .matches(/^\S*$/, "Name without spaces"),
  lastName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your name")
    .matches(/^\S*$/, "Name without spaces"),
  companyName: Yup.string().min(2).required("Please enter your company name"),
  roleInCompany: Yup.string().min(2).required("Please enter your role"),
  email: Yup.string()
    .email("Email Must be Valid")
    .required("Please enter your email"),
  role: Yup.string()
    .required("Pease Select your role")
    .oneOf(["dentist", "club", "franchise"], "Pease Select your role"),
  // phone: Yup.string()
  //   .required("Phone Number is required")
  //   .matches(phoneRegExp, "Phone number is not valid"),
});
export const interestSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter Interest name"),
  link: Yup.string()
    .min(2, "Please enter a valid link")
    .required("Please enter image link"),
  active: Yup.boolean()
    .oneOf([true, false], "Please Select Interest Status")
    .required("Please Select Interest Status"),
});
export const genderSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter gender name"),
  link: Yup.string()
    .min(2, "Please enter a valid link")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url"
    )
    .required("Please enter image link"),
});
export const roleSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter role name"),
});
export const goalSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter goal name"),
  link: Yup.string()
    .min(2, "Please enter a valid link")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url"
    )
    .required("Please enter image link"),
});
export const questionSchema = Yup.object({
  name: Yup.string().min(2).required("Please enter question"),
});
export const addUserFromFranchiseeSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your name")
    .matches(/^\S*$/, "Name without spaces"),
  lastName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your name")
    .matches(/^\S*$/, "Name without spaces"),
  email: Yup.string()
    .email("Email Must be Valid")
    .required("Please enter your email"),
  // phone: Yup.string()
  //   .required("Phone Number is required")
  //   .matches(phoneRegExp, "Phone number is not valid"),
});
export const subscriptionSchema = Yup.object({
  type: Yup.string().required("Pease select your User type"),
  category: Yup.string()
    .required("Pease select your Plan category")
    .oneOf(["Meet AIO", "AIO 360", "I-AIO"], "Pease select your Plan category"),
  price: Yup.number().required("Please enter your price"),
  interval: Yup.string().required("Please Select duartion of your plan"),
});
export const forgotPasswordSchema = Yup.object({
  newPassword: Yup.string().required("Please enter your password"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
});
export const creditCardSchema = Yup.object({
  name: Yup.string()
    .test(
      "test-name",
      "Name is Invalid",
      (value) => valid.cardholderName(value).isValid
    )
    .min(3)
    .max(30)
    .required("Please enter your name"),
  cvc: Yup.string()
    .label("CVC")
    .test(
      "test-expiry",
      "CVV   is Invalid",
      (value) => valid.cvv(value).isPotentiallyValid
    )
    .required("Please enter your cvc"),
  cardNumber: Yup.string()
    .test(
      "test-number",
      "Credit Card number is invalid",
      (value) => valid.number(value).isValid
    )
    .required("Required "),
  expiry: Yup.string()
    .test(
      "test-expiry",
      "Expiry Date  is Invalid",
      (value) => valid.expirationDate(value).isPotentiallyValid
    )
    .required("Expiry Date Required "),
  //cvc2: Yup.string().required("Please enter your expiry date"),
});
