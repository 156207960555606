import { Typography } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Thanks = ({ setProceedToCardDetails }) => {
  const navigate = useNavigate();
  const handleSeeOtherPlans = () => {
    window.location.reload();
    sessionStorage.setItem("planPurchased", true);
  };
  return (
    <div className=" h-96 text-center pt-28 mt-20">
      <Typography className="loginHeading font-bold  text-6xl text-[#2e74ba] ">
        Thanks for Subscribing !
      </Typography>
      <Button
        onClick={handleSeeOtherPlans}
        size="large"
        className="bg-[black] mt-10"
        //loading={isLoading}
        loadingPosition="end"
        variant="contained"
      >
        <span>See Other PLANS</span>
      </Button>
    </div>
  );
};

export default Thanks;
