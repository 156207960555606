import { ActionTypes } from "../Constants/ActionTypes";
const initialState = {
  loggedInUser: {},
  users: [],
  franchises: [],
  goals: [],
  interests: [],
  genders: [],
  roles: [],
  feedback: [],
  allCounts: {},
  singleInterest: {},
  singleUser: {},
  singleGoal: {},
  plans: [],
  specificPlans: [],
};
export const AdminReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ALL_USERS:
      return { ...state, users: payload };
    case ActionTypes.GET_ALL_FRANCHISES:
      return { ...state, franchises: payload };
    case ActionTypes.GET_ALL_GOALS:
      return { ...state, goals: payload };
    case ActionTypes.GET_ALL_FEEDBACK:
      return { ...state, feedback: payload };
    case ActionTypes.GET_ALL_INTERESTS:
      return { ...state, interests: payload };
    case ActionTypes.GET_ALL_GENDERS:
      return { ...state, genders: payload };
    case ActionTypes.GET_ALL_ROLES:
      return { ...state, roles: payload };
    case ActionTypes.GET_ALL_COUNTS:
      return { ...state, allCounts: { ...payload } };
    case ActionTypes.SET_SINGLE_INTEREST:
      return { ...state, singleInterest: { ...payload } };
    case ActionTypes.SET_SINGLE_USER:
      return { ...state, singleUser: { ...payload } };
    case ActionTypes.SET_SINGLE_GOAL:
      return { ...state, singleGoal: { ...payload } };
    case ActionTypes.GET_ALL_PLANS:
      return { ...state, plans: payload };
    case ActionTypes.SET_LOGGEDIN_USER:
      console.log(payload);
      return { ...state, loggedInUser: { ...payload } };
    case ActionTypes.GET_ALL_PLANS_FOR_ADMIN:
      return { ...state, specificPlans: payload };
    default:
      return state;
  }
};
