import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Cards from "react-credit-cards-2";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import PricingCard from "./PricingCard";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import aioappLogo from "../../assets/aioappLogo.png";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { getPlansForUser } from "../../Redux/Actions/UserActions";
import { ToastContainer } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { clearAllPlans } from "../../Redux/Actions/UserActions";
import CardDetails from "./CardDetails";

const pageSize = 3;

const CheckoutForm = () => {
  const [typeSelected, setTypeSelected] = useState("");
  const [proceedToCardDeatils, setProceedToCardDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize,
  });
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.setItem("currentTab", "admin");
    sessionStorage.removeItem("user token");
    sessionStorage.removeItem("admin token");
    sessionStorage.removeItem("planPurchased");
    navigate("/aiomegaPayments");
    dispatch(clearAllPlans());
  };
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.userReducer.plansForUsers);
  const user = useSelector((state) => state.allusers.loggedInUser);
  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to });
  };
  const handleChange = (event) => {
    setLoading(true);
    setTypeSelected(event.target.value);
    dispatch(getPlansForUser(event.target.value, setLoading));
  };

  useEffect(() => {
    setPagination({ ...pagination, count: plans.length });
  }, [plans, pagination.from, pagination.to]);

  useEffect(() => {
    dispatch(getPlansForUser("", setLoading));
  }, []);

  return (
    <div>
      <div className="mt-10 flex sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-around items-center ">
        <div></div>
        <div className="flex sm:flex-col md:flex-col lg:flex-col xl:flex-row">
          <img src={aioappLogo} alt="login page"></img>
          <Typography className="loginHeading font-thin mt-2.5" variant="h3">
            SUBSCRIPTIONS
          </Typography>
        </div>
        <div>
          <Button className="bg-[#2374ba] text-white" onClick={handleLogout}>
            Log Out
          </Button>
        </div>
      </div>

      <ToastContainer />

      {proceedToCardDeatils ? (
        <div>
          <CardDetails setProceedToCardDetails={setProceedToCardDetails} />
        </div>
      ) : (
        <div>
          <div>
            <Grid
              container
              spacing={2}
              className=" mt-4 "
              direction="row"
              justifyContent="center"
            >
              <Typography className=" mt-4 loginHeading font-light ">
                Select User Type
              </Typography>
              <Box sx={{ minWidth: 380 }} className="mt-2">
                <FormControl fullWidth>
                  <InputLabel
                    className="ml-4"
                    id="demo-controlled-open-select-label"
                  >
                    Select
                  </InputLabel>
                  <Select
                    className="ml-4"
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    onChange={handleChange}
                    //open={open}
                    //onClose={handleClose}
                    // onOpen={handleOpen}
                    //value={age}
                    label="Select"
                    defaultValue="Non-User of AIO Device"
                    //onChange={handleChange}
                  >
                    <MenuItem value="Non-User of AIO Device">
                      Non-User of AIO Device
                    </MenuItem>
                    <MenuItem value="AIO REVIVE PREMIER">
                      AIO REVIVE PREMIER
                    </MenuItem>
                    <MenuItem value="AIO SWIFT PREMIER">
                      AIO SWIFT PREMIER
                    </MenuItem>
                    <MenuItem value="AIO GRIT PREMIER">
                      AIO GRIT PREMIER
                    </MenuItem>
                    <MenuItem value="AIO BRUT PREMIER">
                      AIO BRUT PREMIER
                    </MenuItem>
                    <MenuItem value="AIO REVIVE DELUX">
                      AIO REVIVE DELUX
                    </MenuItem>
                    <MenuItem value="AIO SWIFT DELUX">AIO SWIFT DELUX</MenuItem>
                    <MenuItem value="AIO GRIT DELUX">AIO GRIT DELUX</MenuItem>
                    <MenuItem value="AIO BRUT DELUX">AIO BRUT DELUX</MenuItem>
                    <MenuItem value="AIO SWIFT ULTIMATE">
                      AIO SWIFT ULTIMATE
                    </MenuItem>
                    <MenuItem value="AIO GRIT ULTIMATE">
                      AIO GRIT ULTIMATE
                    </MenuItem>
                    <MenuItem value="AIO BRUT ULTIMATE">
                      AIO BRUT ULTIMATE
                    </MenuItem>
                  </Select>
                </FormControl>
                {/* <FormControl fullWidth>
                  
                  <Select
                    fullWidth
                    className="ml-4"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                   // name="role"
                    defaultValue={"No role selected"}
                    onChange={handleChange}
                  >
                    
                    <MenuItem value="Non-User of AIO Device">
                      Non-User of AIO Device
                    </MenuItem>
                    <MenuItem value="AIO REVIVE PREMIER">
                      AIO REVIVE PREMIER
                    </MenuItem>
                    <MenuItem value="AIO SWIFT PREMIER">
                      AIO SWIFT PREMIER
                    </MenuItem>
                    <MenuItem value="AIO GRIT PREMIER">
                      AIO GRIT PREMIER
                    </MenuItem>
                    <MenuItem value="AIO BRUT PREMIER">
                      AIO BRUT PREMIER
                    </MenuItem>
                    <MenuItem value="AIO REVIVE DELUX">
                      AIO REVIVE DELUX
                    </MenuItem>
                    <MenuItem value="AIO SWIFT DELUX">AIO SWIFT DELUX</MenuItem>
                    <MenuItem value="AIO GRIT DELUX">AIO GRIT DELUX</MenuItem>
                    <MenuItem value="AIO BRUT DELUX">AIO BRUT DELUX</MenuItem>
                    <MenuItem value="AIO SWIFT ULTIMATE">
                      AIO SWIFT ULTIMATE
                    </MenuItem>
                    <MenuItem value="AIO GRIT ULTIMATE">
                      AIO GRIT ULTIMATE
                    </MenuItem>
                    <MenuItem value="AIO BRUT ULTIMATE">
                      AIO BRUT ULTIMATE
                    </MenuItem>
                  </Select>
                </FormControl> */}
              </Box>
            </Grid>
          </div>

          <div className="mt-4 px-52  text-center">
            <Grid
              container
              spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}
              columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className="loginHeading font-light">
                  <span className="loginHeading font-bold text-[#2e74ba]">
                    Note:{" "}
                  </span>
                  Please select user type to view all related plans.
                </Typography>
              </Grid>
              {console.log("Plans Retreived", plans)}
              {loading ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="mt-4 flex flex-row"
                >
                  <Skeleton variant="rectangular" width={457} height={500} />
                  <Skeleton
                    variant="rectangular"
                    width={457}
                    height={500}
                    className="ml-10"
                  />
                  <Skeleton
                    variant="rectangular"
                    width={457}
                    height={500}
                    className="ml-10"
                  />
                </Grid>
              ) : plans.length > 0 ? (
                plans
                  // .slice(pagination.from, pagination.to)
                  .map((plan, index) => (
                    <Grid
                      item
                      padding="20px"
                      paddingBottom="30px"
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                    >
                      <PricingCard
                        plan={plan}
                        setProceedToCardDetails={setProceedToCardDetails}
                      />
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12} sm={12} md={12} justifyContent="center">
                  <Typography className=" mt-4 loginHeading font-light ">
                    No Plan Found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
          <div>
            {!loading
              ? plans.length > 8 && (
                  <Grid
                    container
                    spacing={2}
                    className=" mt-4 mb-4"
                    direction="row"
                    justifyContent="center"
                  >
                    <Pagination
                      count={Math.ceil(pagination.count / pageSize)}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Grid>
                )
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
