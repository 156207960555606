import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signupSchema } from "../../Yup/index";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../../Redux/Actions/AdminActions";
import LoadingButton from "@mui/lab/LoadingButton";
const theme = createTheme();

const initialValues = {
  email: "",
  password: "",
};
export default function SignIn({ pathname }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const Formik = useFormik({
    initialValues: { initialValues },
    validationSchema: signupSchema,

    onSubmit: async (values) => {
      console.log(values);
      setIsLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/login?platform=web`,
          {
            email: values.email,
            password: values.password,
          }
        )
        .then((res) => {
          console.log("Subscri[tion Portal", res);
          console.log(
            "Pathname",
            pathname,
            res.data.user.role.name,
            res.data.user.role.name === "User"
          );
          if (
            res.data.user.role.name === "User" &&
            pathname === "/aiomegaPayments"
          ) {
            console.log("User", res);
            dispatch(setLoggedInUser(res.data.user));
            sessionStorage.setItem("user token", res.data.token);
            navigate("/buySubscription");
            setIsLoading(false);

            setIsLoading(false);
          } else if (
            res.data.user.role == "admin" &&
            pathname !== "/aiomegaPayments"
          ) {
            console.log("Admin");
            dispatch(setLoggedInUser(res.data.user));
            sessionStorage.setItem("admin token", res.data.token);
            navigate("/admin");
            setTimeout(() => {
              toast.success("Logged In successfully", {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              });
            }, 1000);
            setIsLoading(false);
          } else if (
            res.data.user.role.name == "club" ||
            res.data.user.role.name == "dentist" ||
            (res.data.user.role.name == "franchise" &&
              pathname !== "/aiomegaPayments")
          ) {
            console.log("Partner");
            dispatch(setLoggedInUser(res.data.user));
            sessionStorage.setItem("admin token", res.data.token);
            navigate("/franchise/users");
            setIsLoading(false);
          } else if (res.data.user.role === "user") {
            toast.error("Requested User cannot access admin panel");
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
            toast.error("Invalid Username or Password");
            setIsLoading(false);
          } else {
            toast.error(err);
            setIsLoading(false);
          }
          console.log("Error", err);
        });
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ boxShadow: 3, paddingTop: "2rem", paddingBottom: "2rem" }}
        className="mt-4  md:mt-4 lg:mt-40 "
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ToastContainer autoClose={2000} />
          <Typography className="loginHeading text-[#2E74BA] font-bold text-2xl">
            SIGN IN
          </Typography>
          <Box component="form" onSubmit={Formik.handleSubmit} noValidate>
            <TextField
              className="inputRounded"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              defaultValue={Formik.values.email}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              autoComplete="email"
              error={Formik.errors.email && Formik.touched.email}
              helperText={
                Formik.errors.email && Formik.touched.email
                  ? Formik.errors.email
                  : null
              }
            />
            {/* {Formik.errors.email && Formik.touched.email ? (
              <label className="form-error" style={{ color: "red" }}>
                {Formik.errors.email}
              </label>
            ) : null} */}
            <TextField
              className="inputRounded"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleClickShowPassword}
                    className="cursor-pointer"
                  >
                    {" "}
                    {showPassword ? (
                      <VisibilityOff color={"#2e743a"} />
                    ) : (
                      <Visibility />
                    )}
                  </InputAdornment>
                ),
              }}
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              defaultValue={Formik.values.password}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              autoComplete="current-password"
              error={Formik.errors.password && Formik.touched.password}
              helperText={
                Formik.errors.password && Formik.touched.password
                  ? Formik.errors.password
                  : null
              }
            />

            {/* {Formik.errors.password && Formik.touched.password ? (
              <label className="form-error" style={{ color: "red" }}>
                {Formik.errors.password}
              </label>
            ) : null} */}

            {/* <LoadingButton
              type="submit"
              disabled={!Formik.dirty || !Formik.isValid}
              fullWidth
              variant="contained"
              loading={}
              className={
                !Formik.dirty || !Formik.isValid
                  ? "bg-[#AAAAAA]"
                  : "bg-[#2e74ba] loginHeading mt-4 hover:bg-stone-900 "
              }
            >
              Sign In
            </LoadingButton> */}
            <LoadingButton
              size="large"
              type="submit"
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
              fullWidth
              className={
                !Formik.dirty || !Formik.isValid
                  ? "bg-[#AAAAAA]"
                  : "bg-[#2e74ba] loginHeading mt-4 hover:bg-stone-900 "
              }
            >
              <span>Sign In</span>
            </LoadingButton>

            {/* <Button
              type="submit"
              fullWidth
              onClick={()=>navigate("/upgradeToPRO")}
              variant="contained"
              endIcon={<HttpsIcon/>}
              className=" loginHeading mt-4 bg-[#000000] hover:bg-stone-900 "
            >
              Upgrade to PRO
            </Button> */}
            <Grid container className="mt-4">
              <Grid item xs>
                <Link href="/forgotPassword" variant="body2">
                  <span className="loginHeading"> Forgot password?</span>
                </Link>
                {/* <Typography className="testing">Testing</Typography> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
