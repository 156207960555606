import { Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import aioappLogo from "../../assets/aioappLogo.png";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";
import { MuiOtpInput } from "mui-one-time-password-input";
import MuiPhoneNumber from "mui-phone-number";
import axios from "axios";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../Yup";
import { useSelector, useDispatch } from "react-redux";
import { forgetPasswordConfirmation } from "../../Redux/Actions/AdminActions";
import Link from "@mui/material/Link";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const ForgotPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [dialCode, setDialCode] = useState(0);
  const [showOptScreen, seShowOtpScreen] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [otpCode, setOtp] = React.useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.allusers);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const verifyOtPHandler = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/user/verify-otp`, {
        countryCode: dialCode,
        phoneNo: phoneNumber,
        otp: otpCode,
      })
      .then((res) => {
        if (res.data.success) {
          seShowOtpScreen(false);
          setShowNewPassword(true);
        } else {
          console.log(res);
        }
      });
  };
  const phoneNumberHandler = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/user/forgot-password`, {
        countryCode: dialCode,
        phone: phoneNumber,
      })
      .then((res) => {
        if (res.data.success) {
          console.log(res);
          console.log(res.data.success);
          seShowOtpScreen(true);
        } else {
          console.log(res);
        }

        console.log(res);
      });
  };
  const handleOnChange = (value, allDetails) => {
    if (allDetails.dialCode.length > 2) {
      setPhoneNumber(value.slice(allDetails.dialCode.length + 1));
      setDialCode(allDetails.dialCode);
    } else {
      setPhoneNumber(value.slice(allDetails.dialCode.length + 1));
      setDialCode(allDetails.dialCode);
    }
  };
  const Formik = useFormik({
    initialValues: { initialValues },
    validationSchema: forgotPasswordSchema,

    onSubmit: async (values) => {
      dispatch(forgetPasswordConfirmation(values, dialCode, phoneNumber));
    },
  });
  console.log(user);
  return (
    <>
      <ToastContainer position="top-center" autoClose={3000} />
      <Grid className="flex justify-center mt-20">
        <img src={aioappLogo} alt="login page"></img>
      </Grid>
      <Grid className="flex justify-center mt-10" gap={2}>
        <Typography className="loginHeading font-thin text-3xl">
          Forgot Password?{" "}
        </Typography>
        <Typography className="loginHeading font-bold text-3xl">
          No Worries, We Got your Back !
        </Typography>
      </Grid>

      {showNewPassword ? (
        <Grid className="mt-10 flex justify-center">
          <Box
            sx={{ boxShadow: 3 }}
            className="2xl:w-1/3 xl:w-2/4 lg:w-2/4 md:w-2/4  p-4 flex flex-col text-center justify-center items-center"
          >
            <form onSubmit={Formik.handleSubmit}>
              <Typography className="loginHeading font-thin" variant="p">
                Please Setup a new password.
              </Typography>
              <Typography className=" mt-4 loginHeading font-light jutify-left text-left ">
                New Password
              </Typography>
              <TextField
                className="loginHeading mt-2 font-light "
                fullWidth
                name="newPassword"
                defaultValue={Formik.values.newPassword}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                error={Formik.errors.newPassword && Formik.touched.newPassword}
                helperText={
                  Formik.errors.newPassword && Formik.touched.newPassword
                    ? Formik.errors.newPassword
                    : null
                }
                placeholder="New Password" //optional
              />

              <Typography className=" mt-4 loginHeading font-light  jutify-left text-left">
                Confirm Password
              </Typography>
              <TextField
                className="loginHeading mt-2 font-light "
                fullWidth
                name="confirmPassword"
                defaultValue={Formik.values.confirmPassword}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                error={
                  Formik.errors.confirmPassword &&
                  Formik.touched.confirmPassword
                }
                helperText={
                  Formik.errors.confirmPassword &&
                  Formik.touched.confirmPassword
                    ? Formik.errors.confirmPassword
                    : null
                }
                placeholder="Confirm Password" //optional
              />

              <Button
                type="submit"
                variant="contained"
                className="w-1/4 loginHeading mt-4 bg-[#2e74ba] hover:bg-stone-900 "
              >
                Reset
              </Button>
            </form>
            <Link className="mt-4" href="/" underline="always">
              {"Back to Sign"}
            </Link>
          </Box>
        </Grid>
      ) : showOptScreen ? (
        <Grid className="mt-10 flex justify-center">
          <Box
            sx={{ boxShadow: 3 }}
            className="2xl:w-1/3 xl:w-2/4 lg:w-2/4 md:w-2/4  p-4 flex flex-col text-center justify-center items-center"
          >
            <Typography className="loginHeading font-thin" variant="p">
              Please Verify the OTP. We have send the OTP code on the provided
              phone number.
            </Typography>
            <MuiOtpInput
              className="mt-4"
              value={otpCode}
              length={6}
              onChange={handleChange}
            />

            <Button
              onClick={verifyOtPHandler}
              variant="contained"
              className="w-1/4 loginHeading mt-4 bg-[#2e74ba] hover:bg-stone-900 "
            >
              Verify
            </Button>
          </Box>
        </Grid>
      ) : (
        <Grid className="mt-10 flex justify-center">
          <Box
            sx={{ boxShadow: 3 }}
            className="2xl:w-1/3 xl:w-2/4 lg:w-2/4 md:w-2/4  p-4 flex flex-col text-center justify-center items-center"
          >
            <Typography className="loginHeading font-thin" variant="p">
              Lost your password? Please enter your registered email address.
              You will receive a link to create a new password via email.
            </Typography>
            {/* <TextField
          error={emailError}
          helperText={emailError ? "Verified Email Required" : null}
          className="mt-4 w-3/4"
          label="Email"
          variant="outlined"
          value={registeredEmail}
          onChange={(e) => setRegisteredEmail(e.target.value)}
        /> */}
            <MuiPhoneNumber
              autoFormat={false}
              className="mt-4"
              defaultCountry={"pk"}
              onChange={handleOnChange}
            />

            <Button
              onClick={phoneNumberHandler}
              variant="contained"
              className="w-1/4 loginHeading mt-4 bg-[#2e74ba] hover:bg-stone-900 "
            >
              Submit
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default ForgotPassword;
