import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

axios.interceptors.response.use(null, async (error) => {
  console.log(error.response.data.message);
  if (error.config && error.response && error.response.status !== 401) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  }

  return Promise.reject(error);
});
const request = axios;
export default request;
