import React from "react";
import { useState } from "react";
export const AdminDashboardRoute = React.createContext();

export default function AdminRouteContext(props) {
  const [dashboardRoute, setDashboardRoute] = useState();

  return (
    <AdminDashboardRoute.Provider value={{ setDashboardRoute, dashboardRoute }}>
      {props.children}
    </AdminDashboardRoute.Provider>
  );
}
